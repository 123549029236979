.page-login .logo-container {
    flex: 1 0 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
}
.page-login .login-container {
    border: solid 1px #ccc;
    border-radius: 10px;
    max-width: 390px;
    margin: auto;
    margin-top: 0;
    padding: 40px;
}
.page-login .login-container h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    text-align: center;
}
.page-login .login-container button {
    width: 100%;
}
.page-login .maquinaslogadas-container {
    margin: auto;
    margin-top: 0;
    padding-top: 20px;
}