.page-opform #main {
    min-height: calc(100vh - 69px);
    margin-top: -40px;
    padding-right: 0;
    padding-left: 0;
}
.page-opform #main > .row {
    margin-right: 0;
    margin-left: 0;
}
.page-opform #main > .row > .col {
    padding-left: 0;
    padding-right: 0;
}
.page-opform .form {
    --color-alocar: #000;
    --color-setup: #0012ff;
    --color-producao: #177500;
    --color-parada: #ff0000;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: calc(100vh - var(--height-header) - var(--height-footer));
}
.page-opform .form .ordemproducao {
    border: solid 3px #ccc;
    color: var(--primary2);
    display: grid;
    place-content: center;
    justify-content: stretch;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 30px 15px;
}
.page-opform .form .ordemproducao .label {
    align-self: center;
    font-size: 1em;
    text-align: right;
}
.page-opform .form .ordemproducao .value {
    font-size: 1.3em;
    font-weight: bold;
}
.page-opform .form .evento {
    border: solid 3px #ccc;
    display: grid;
    place-content: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 30px 15px;
}
.page-opform .form .evento button {
    font-weight: bold;
    min-width: 250px;
    padding: 10px;
}
.page-opform .form .evento .tipo {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.6em;
    font-weight: bold;
}
.page-opform .form .evento.alocar, 
.page-opform .form .evento.alocar button {
    border-color: var(--color-alocar);
    color: var(--color-alocar);
}
.page-opform .form .evento.setup, 
.page-opform .form .evento.setup button {
    border-color: var(--color-setup);
    color: var(--color-setup);
}
.page-opform .form .evento.producao,
.page-opform .form .evento.producao button {
    border-color: var(--color-producao);
    color: var(--color-producao);
}
.page-opform .form .evento.parada_programada,
.page-opform .form .evento.parada_nao_programada,
.page-opform .form .evento.parada_programada button,
.page-opform .form .evento.parada_nao_programada button {
    border-color: var(--color-parada);
    color: var(--color-parada);
}
.page-opform .form .evento #cronometro {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
}
.page-opform .form .evento .info {
    color:#3e3e3e;
    margin-bottom: 30px;
    text-align: center;
}
.page-opform .form .evento .concluir {
    text-align: center;
}
.page-opform .form .log {
    border: solid 1px #ccc;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    overflow: auto;
}
.page-opform .form .log .sync-ok {
    color: #177500;
    font-weight: bold;
}
.page-opform .form .log table {
    font-size: 12px;
    width: 100%;
}
.page-opform .form .log th {
    border: solid 1px #ccc;
    padding: 5px 10px;
}
.page-opform .form .log td {
    border: solid 1px #ccc;
    padding: 5px 10px;
}
.page-opform .form .menu {
    background-color: #ccc;
    display: grid;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 20px;
}
.page-opform .form .menu button {
    font-weight: bold;
    padding: 15px 0;
    width: 100%;
}
.page-opform .form .menu button:disabled {
    border-color: rgb(119, 119, 119);
    color: rgb(119, 119, 119);
}
.page-opform .form .menu .alocar,
.page-opform .form .menu .desalocar {
    border-color: var(--color-alocar);
    color: var(--color-alocar);
}
.page-opform .form .menu .desalocar {
    border-color: var(--color-alocar);
    color: var(--color-alocar);
}
.page-opform .form .menu .addsetup {
    border-color: var(--color-setup);
    color: var(--color-setup);
}
.page-opform .form .menu .addproducao {
    border-color: var(--color-producao);
    color: var(--color-producao);
}
.page-opform .form .menu .addparada {
    border-color: var(--color-parada);
    color: var(--color-parada);
}
.page-opform .form .menu ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    list-style: none;
    justify-content: space-between;
    padding: 0;
}
.page-opform .form .menu ul > li {
    margin-bottom: 2em;
}
.page-opform .form .menu ul > li:last-of-type {
    margin-bottom: 0;
}
.page-opform .form .menu .msg-naopodeproduzir-container {
    font-size: 22px;
    text-align: center;
    margin: auto;
    width: 300px;
}


#modalOperador ul.operadores {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
}
#modalOperador ul.operadores li {
    flex: 0 0 250px;
    margin-right: 30px;
    margin-bottom: 30px;
}
#modalOperador ul.operadores button {
    background-color: #fff;
    display: grid;
    font-weight: bold;
    height: 75px;
    line-height: 1.1;
    padding: 10px 10px;
    place-content: center;
    width: 100%;
}
#modalOperador ul.operadores .btn-outline-dark:hover {
    color: initial;
}

#modalEscolherMotivo ul.motivos {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
}
#modalEscolherMotivo ul.motivos li {
    flex: 0 0 300px;
    margin-right: 30px;
    margin-bottom: 30px;
}
#modalEscolherMotivo ul.motivos button {
    background-color: #fff;
    display: grid;
    font-weight: bold;
    height: 75px;
    line-height: 1.1;
    padding: 10px 10px;
    place-content: center;
    text-transform: uppercase;
    width: 100%;
}
#modalEscolherMotivo ul.motivos .btn-outline-dark:hover {
    color: initial;
}