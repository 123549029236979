/* ---------------------- */
/* HTML e Bootstrap reset */
/* ---------------------- */
:root {
    --dark: #615540;
    --primary1: #e1af50;
    --primary1-rgb: 225,175,80;
    --primary1-hover: #d09f42;
    --primary1-border-hover: #c5963b;
    --link: #e1af50;
    --link-rgb: 225,175,80;
    --link-hover: #d09f42;
    --primary2: #09093a;
    --form-components-border: #979797;
    --height-header: 44px;
    --height-footer: 25px;
}
a {
    color: var(--link);
    font-weight: 500;
    text-shadow: 0 0 black;
}
a:hover {
    color: var(--link-hover);
}
body {
    color: var(--primary2);
}
@media print {
    @page {
        size: auto;
        margin: auto;
    }
    html, body {
        margin: 0;
        padding: 0 !important;
    }
    #main {
        min-height: auto;
    }
    .container {
        width: 1000px;
    }
    .container, 
    .container-fluid, 
    .container-lg, 
    .container-md, 
    .container-sm, 
    .container-xl {
        padding: 0;
        margin: 0;
        min-width: auto !important;
        max-width: none !important;
    }
}
img {
    width: auto\9;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
}
button {
    border-radius: 4px;
}
.modal-backdrop ~ .modal-backdrop {
    display: none;
}
/* BTN PRIMARY */
.btn-primary,
.btn-primary.disabled, 
.btn-primary:disabled {
    background-color: var(--primary1);
    border-color: var(--primary1);
    color: #fff;
}
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle,
.btn-primary.focus, 
.btn-primary:focus {
    background-color: var(--primary1-hover);
    border-color: var(--primary1-border-hover);
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary1-rgb),.5);
}
/* BTN LINK */
.btn-link {
    color: var(--link);
    font-weight: 500;
    text-shadow: 0 0 black;
}
.btn-link:hover {
    color: var(--link-hover);
}
/* NAV PILLS */
.nav-pills .nav-link.active, 
.nav-pills .show>.nav-link {
    background-color: var(--primary1);
}
/* TABLE */
.table .thead-dark th {
    color: #fff;
}
.table caption {
    caption-side: top;
    background-color: #282f35;
    color: #fff;
    font-weight: bold;
    padding: 2px 10px;
}
.table {
    border-collapse: separate;
    border-spacing: 0;
}
.table th {
    white-space: nowrap;
}
.table th,
.table td {
    padding-left: 12px;
    padding-right: 12px;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #e3e3e3;
}
.form-control,
.react-select__control,
.input-group-text {
    border-color: var(--form-components-border) !important;
}
.nav-tabs {
    border-bottom-color: var(--form-components-border) !important;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
    border-color: var(--form-components-border) var(--form-components-border) #fff !important;
}
.nav-tabs .nav-link:focus, 
.nav-tabs .nav-link:hover {
    border-bottom-color: transparent;
}
/* MODAL */
.modal-header {
    background-color: var(--primary1);
}
.modal-body {
    background-color: #ccc;
}

/* PAGE LINK */
.page-link {
    border-color: var(--link);
    color: var(--link);
    font-weight: bold;
}
.page-link:hover {
    color: var(--link-hover);
}
.page-link:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--link-rgb), .5);
}
/* DROPDOWN */
.dropdown-item.active, 
.dropdown-item:active {
    background-color: var(--link);
}
/* --- */

/* --------- */
/* ESTRUTURA */
/* --------- */
/* HEADER */
.layout-logged #header {
    background-color: var(--primary2);
    color: #fff;
    margin-bottom: 40px;
    height: var(--height-header);
}
.layout-logged #header .btn-logout {
    color: #ffffff;
}
.layout-logged #header .menu1,
.layout-logged #header .menu2 {
    font-size: 1.2em;
    font-weight: bold;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.layout-logged #header .menu1 .icon,
.layout-logged #header .menu2 .icon {
    font-size: 20px;
}
.layout-logged #header .menu1 a {
    color: #fff;
    line-height: 0;
}
.layout-logged #header .navbar {
    max-width: 1100px;
    margin: auto;
}
.layout-logged #header .navbar .nav-link {
    color: rgba(255, 255, 255, .8);
}
.layout-logged #header .navbar .nav-link:hover {
    color: rgba(255, 255, 255, 1);
}
.layout-logged #header .perfil-container .dropdown-toggle {
    background-color: transparent;
    border: none;
    color: rgba(255, 255, 255, .8);
    font-weight: 500;
}

#footer {
    padding-left: 0;
    padding-right: 0;
    position: relative;
}
#footer .faixa {
    background-color: var(--primary1);
    bottom: 0;
    color: #000;
    height: var(--height-footer);
    padding-right: 30px;
    text-align: right;
    width: 100%;
}
/* NAV GESTOR */
#navbarGestor {
    background-color: #e0e0e0;
    color: var(--primary2);
    padding: 10px;
    text-align: center;
}
#navbarGestor select {
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
    padding: 2px 10px;
}
/* MAIN */
#main {
    min-height: calc(100vh - 303px);
}
.modo-gestor #main {
    min-height: calc(100vh - 348px);
}
@media print {
    #main {
        min-height: auto;
    }
    .modo-gestor #main {
        min-height: auto;
    }
}
/* --- */


/* TABELA COM SCROLL */
.table-container-scroll {
    height: 80vh;
    overflow: auto;
    resize: vertical;
}
/* --- */

/* TABELA COM LATERAL FIXA */
.table thead.sticky th {
    position: sticky;
    top: 0;
    z-index: 1;
}
.table tbody > tr > td.sticky {
    left: 0;
    position: sticky;
}
.table tbody > tr > td.sticky {
    background-color: #fff;
}
.table-striped tbody tr:nth-of-type(odd) > td.sticky {
    background-color: #e3e3e3;
}
/* --- */

/* TOAST */
.Toastify__toast--default {
    background-color: #fff380;
    color: black;
}
/* --- */

.page-title {
    margin-bottom: 50px;
}

.nav-pills {
    margin-bottom: 45px;
}

/* MENU PAGINAÇÃO */
.pagination .-item-dropdown {
    padding: 0 10px;
}
.pagination .-item-dropdown > select {
    border-color: var(--link) !important;
    cursor: pointer;
}
/* --- */