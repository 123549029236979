.page-opestamparia .ordens-container table td {
    white-space: nowrap;
}

.page-opestamparia .filtro-container {
    border: solid 1px #9c9c9c;
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 20px 20px 10px;
}
.page-opestamparia .filtro-container > form > .form-group-id {
    margin-top: 20px;
}
.page-opestamparia .filtro-container .status-container label {
    cursor: pointer;
    margin-right: 10px;
}
.page-opestamparia .filtro-container .form-actions {
    text-align: right;
}
.page-opestamparia .filtro-container .form-actions > button:first-of-type {
    margin-right: 5px;
}

@media (min-width: 992px) {
    .page-opestamparia .filtro-container > form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .page-opestamparia .filtro-container > form > *:not(.periodo-container) {
        align-self: center;
        flex-shrink: 1;
        flex-grow: 1;
        margin-right: 10px;
    }
    .page-opestamparia .filtro-container > form > .form-group-id {
        flex-basis: 100px;
        margin-top: 0;
    }
    .page-opestamparia .filtro-container > form > .form-group-of {
        flex-basis: 120px;
    }
    .page-opestamparia .filtro-container > form > .form-group-ref {
        flex-basis: 120px;
    }
    .page-opestamparia .filtro-container > form > :last-child {
        margin-right: 0;
    }
    .page-opestamparia .filtro-container .periodo-container {
        flex-basis: calc(50% - 25px);
    }
    .page-opestamparia .filtro-container .periodo-container > .form-group {
        display: flex;
        justify-content: space-between;
    }
    .page-opestamparia .filtro-container .periodo-container > .form-group > * {
        width: calc(50% - 5px);
    }
}